import React, { Component } from "react";
import $ from "jquery";
import grocery from "../assets/images/grocery_graphic.png";
import checkbox from "../assets/images/checkbox-icon.png";
import Header from "./Header.js";
import Footer from "./ProductFooter.js";
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { api_url } from "../Config.js";
import { PostData } from "./PostData.js";
import loaderImage from "../assets/images/770.gif";
class TimeSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      backupProducts: [],
      categories: [],
      redirectToReferrer: false,
      user_id: JSON.parse(sessionStorage.getItem("userData"))
        ? JSON.parse(sessionStorage.getItem("userData"))
        : JSON.parse(localStorage.getItem("userData")),
      base_url: api_url.base_url,
      img_url: api_url.img_url,
      loading: false,
      cartProduct:
        JSON.parse(localStorage.getItem("cartProduct")) != null
          ? JSON.parse(localStorage.getItem("cartProduct"))
          : [],
      total_amount: localStorage.getItem("total_amount")
        ? localStorage.getItem("total_amount")
        : 0,

      dvalue: 0,
      redirect: false,
      prefix: "",
      suffix: "",
      btnLoading: false,
      editData: [],
      decimalValue:null,
    };
  }

  componentDidUpdate() {
    var selector = ".cata-sub-slider li a";
    $(selector).on("click", function () {
      $(selector).removeClass("active");
      $(this).addClass("active");
    });
  }

  componentDidMount() {
     const prefixurl = this.state.base_url + "sale_api/GetPrefix";
    fetch(prefixurl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            prefix: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
      const decimalsUrl = this.state.base_url + "sale_api/getDecimals";
    fetch(decimalsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            decimalValue: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
    const sufixurl = this.state.base_url + "sale_api/GetSuffix";
    fetch(sufixurl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            suffix: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
    this.setState({ btnLoading: true });

    $(".cata-sub-slider").on("scroll", function () {
      var val = $(this).scrollLeft();
      if (
        $(this).scrollLeft() + $(this).innerWidth() >=
        $(this)[0].scrollWidth
      ) {
        $(".cata-sub-slider .nav-next-").hide();
      } else {
        $(".cata-sub-slider .nav-next-").show();
      }
      if (val == 0) {
        $(".cata-sub-slider .nav-prev-").hide();
      } else {
        $(".cata-sub-slider .nav-prev-").show();
      }
    });
    $(".cata-sub-slider .nav-next-").on("click", function () {
      $(".cata-sub-slider").animate({ scrollLeft: "+=460" }, 500);
    });
    $(".cata-sub-slider .nav-prev-").on("click", function () {
      $(".cata-sub-slider").animate({ scrollLeft: "-=460" }, 500);
    });

    $(".cata-sub-nav").on("scroll", function () {
      var val = $(this).scrollLeft();

      if (
        $(this).scrollLeft() + $(this).innerWidth() >=
        $(this)[0].scrollWidth
      ) {
        $(".nav-next").hide();
      } else {
        $(".nav-next").show();
      }

      if (val == 0) {
        $(".nav-prev").hide();
      } else {
        $(".nav-prev").show();
      }
    });
    $(".nav-next").on("click", function () {
      $(".cata-sub-nav").animate({ scrollLeft: "+=43.2" }, 200);
    });
    $(".nav-prev").on("click", function () {
      $(".cata-sub-nav").animate({ scrollLeft: "-=43.2" }, 200);
    });

    this.setState({ loading: true });
    const getUrl = this.state.base_url + "sale_api/getAllDetailsOnce";
    fetch(getUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            products: result.products,
            backupProducts: result.products,
            categories: result.categories,
            loading: false,
            btnLoading: false,
          });
        },
        (error) => {
          this.setState({ loading: false });
        }
      );

    if (
      localStorage.getItem("orderId") !== null &&
      localStorage.getItem("cartProduct") === null
    ) {
      const getIdUrl =
        this.state.base_url +
        "sale_api/getBySaleOrder/" +
        localStorage.getItem("orderId");
      fetch(getIdUrl)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.order_details) {
              this.setState(
                {
                  editData: result.order_details,
                  // total_amount:(parseFloat(result.basic_details.total)).toFixed(this.state.decimalValue) ,
                  loading: false,
                },
                function () {
                  // console.log(result.basic_details.total)

                  this.state.editData.map((product, index) => {
                    const uname = product.product_id;
                    const upname = product.productName;
                    const uuom = product.UOMName;
                    const uqty = product.qty;
                    const nunit_price = parseFloat(product.rate).toFixed(this.state.decimalValue);
                    const totalprice = nunit_price * uqty;

                        let discountValue = 0;
                        let printValue = 0;
                        let ActualValue =0;
                         const discount_type = localStorage.getItem('dis_type');
                         const discount_amount = localStorage.getItem('discount');
                         if(discount_type === '1')
                         {
                           discountValue = discount_amount/100;
                           printValue = totalprice * discountValue;
                           ActualValue = totalprice - printValue;
                           this.state.total_amount += ActualValue;
                         }
                         else{
                           discountValue = discount_amount;
                           ActualValue = totalprice - (discountValue*uqty);
                           this.state.total_amount += ActualValue;
                         }

                    const verynewproduct = {
                      name: uname,
                      qty: uqty,
                      unit_price: nunit_price,
                      pname: upname,
                      uom: uuom,
                    };

                    this.state.cartProduct.push(verynewproduct);
                  });

                  localStorage.setItem(
                    "cartProduct",
                    JSON.stringify(this.state.cartProduct)
                  );
                  localStorage.setItem(
                    "total_amount",
                    JSON.stringify(this.state.total_amount)
                  );
                }
              );
            }
          },
          (error) => {}
        );
    }



    $(".request_door_click").on("click", function () {});
    $(".request_door_click").click(function () {
      $(".process_complete").hide();
    });
    $(".request_door_click").click(function () {
      $(".last_step").show();
    });
  }

  componentWillMount() {
    if (sessionStorage.getItem("userData")) {
      this.getUserData();
    } else if (localStorage.getItem("userData")) {
      this.getUserData();
    } else {
      this.setState({ redirectToReferrer: true });
    }
  }
  getUserData() {
    JSON.parse(sessionStorage.getItem("userData"));
    JSON.parse(localStorage.getItem("userData"));
  }

  searchItem(e) {
    this.setState({ btnLoading: true });
    var search = e.target.value;

    if (search == null) {
      this.setState({
        products: this.state.backupProducts,
      });
    } else {
      this.setState({ loading: true });
      const searchurl =
        this.state.base_url + "sale_api/getSearchResult?search=" + search;
      fetch(searchurl)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result == null) {
              this.setState({
                products: this.state.backupProducts,
                loading: false,
                btnLoading: false,
              });
            } else {
              this.setState({
                products: result,
                loading: false,
                btnLoading: false,
              });
            }
          },
          (error) => {
            this.setState({ loading: false, btnLoading: false });
          }
        );
    }
  }

  selectedCategory(e) {
    var select = e.target.name;

    if (select == null || select == 0) {
      this.setState({
        products: this.state.backupProducts,
      });
    } else {
      this.setState({ loading: true, btnLoading: true });
      const selecturl =
        this.state.base_url + "sale_api/getselectCategory?select=" + select;
      fetch(selecturl)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result == null) {
              this.setState({
                products: this.state.backupProducts,
                loading: false,
                btnLoading: false,
              });
            } else {
              this.setState({
                products: result,
                loading: false,
                btnLoading: false,
              });
            }
          },
          (error) => {
            this.setState({ loading: false, btnLoading: false });
          }
        );
    }
  }

  async increaseQty(e) {
     let discountValue = 0;
    const discount_type = localStorage.getItem('dis_type');
    const discount_amount = localStorage.getItem('discount');
    if(discount_type === '1')
    {
      discountValue = discount_amount/100;
    }
    else{
      discountValue = discount_amount;
    }

    var product_name = e.target.title;
    var uom = e.target.placeholder ? e.target.placeholder : "";
    var unit_price = e.target.id;
    var targetnaem = e.target.name;

    var product = {
      name: e.target.name,
      qty: 1,
      unit_price: unit_price,
      pname: product_name,
      uom: uom,
    };

    // await this.state.cartProduct.push(product)
    for (var i = 0; i < this.state.cartProduct.length; i++) {
      var sname = this.state.cartProduct[i].name;

      var slectproduct = this.state.cartProduct[i];
      const cartProduct = [...this.state.cartProduct];
      if (sname == targetnaem) {
        var svalue = slectproduct.qty;

        const updateditems = cartProduct.filter(
          (item) => item.name !== slectproduct.name
        );

        this.setState({ cartProduct: updateditems }, function () {
          var nproduct = {
            name: targetnaem,
            qty: parseFloat(svalue) + parseFloat(1),
            unit_price: unit_price,
            pname: product_name,
            uom: uom,
          };
          this.state.cartProduct.push(nproduct);

          localStorage.setItem(
            "cartProduct",
            JSON.stringify(this.state.cartProduct)
          );
        });
      }
    }

    await this.state.cartProduct.push(product);
    let increaseAmount = 0;
    if(discount_type==='1')
        {
           increaseAmount =  discountValue * unit_price;
        }
        else
        {
            increaseAmount = discountValue;
        }
    await this.setState((old) => {
      return {
        total_amount:
          parseFloat(old.total_amount) +
          parseFloat(
            unit_price * 1 -
              (increaseAmount)
          ),
      };
    });
    await localStorage.setItem(
      "cartProduct",
      JSON.stringify(this.state.cartProduct)
    );
    localStorage.setItem(
      "total_amount",
      parseFloat(this.state.total_amount).toFixed(this.state.decimalValue)
    );
  }

  async decreaseQty(e) {
   let discountValue = 0;
    const discount_type = localStorage.getItem('dis_type');
    const discount_amount = localStorage.getItem('discount');
    if(discount_type === '1')
    {
      discountValue = discount_amount/100;
    }
    else{
      discountValue = discount_amount;
    }


    var product_name = e.target.title;
    var uom = e.target.placeholder ? e.target.placeholder : "";
    var unit_price = e.target.id;

    const targetvalue = e.target.name;

    this.state.cartProduct.map((c, i) => {
      if (c.name == targetvalue) {
        if (c.qty > 1) {
          const olvalue = c.qty;
          const olproduct = [...this.state.cartProduct];
          const delit = olproduct.filter((item) => item.name !== targetvalue);
          this.setState(
            {
              cartProduct: delit,
            },
            function () {
              this.state.cartProduct.push({
                name: targetvalue,
                qty: olvalue - parseFloat(1),
                unit_price: unit_price,
                pname: product_name,
                uom: uom,
              });
               let decreaseAmount = 0;
                if(discount_type==='1')
                    {
                       decreaseAmount =  discountValue * unit_price;
                    }
                    else
                    {
                        decreaseAmount = discountValue;
                    }
              this.setState((old) => {
                return {
                  total_amount:
                    parseFloat(old.total_amount) -
                    parseFloat(
                      unit_price * 1 -
                        (decreaseAmount)
                    ),
                };
              });
              localStorage.setItem(
                "cartProduct",
                JSON.stringify(this.state.cartProduct)
              );
            }
          );
        } else {
          const oproduct = [...this.state.cartProduct];
          const dit = oproduct.filter((item) => item.name !== targetvalue);
          this.setState(
            {
              cartProduct: dit,
            },
            function () {
               let decAmount = 0;
                if(discount_type==='1')
                    {
                       decAmount =  discountValue * unit_price;
                    }
                    else
                    {
                        decAmount = discountValue;
                    }
              this.setState((old) => {
                return {
                  total_amount:
                    parseFloat(old.total_amount) -
                    parseFloat(
                      unit_price * 1 -
                        (decAmount)
                    ),
                };
              });
              localStorage.setItem(
                "cartProduct",
                JSON.stringify(this.state.cartProduct)
              );
              localStorage.setItem(
                "total_amount",
                parseFloat(this.state.total_amount).toFixed(this.state.decimalValue)
              );
            }
          );
        }
      }
    });
  }

  saveProducts() {
    this.setState({ redirect: true });
  }

  render() {
    let discountValue = 0;
    const discount_type = localStorage.getItem('dis_type');
    const discount_amount = localStorage.getItem('discount');
    if(discount_type === '1')
    {
      discountValue = discount_amount/100;
    }
    else{
      discountValue = discount_amount;
    }

    let sign = '';
    if(discount_type === '1')
    {
      sign = '%';
    }
    else{
      sign = this.state.suffix;
    }

    const { editData } = this.state;
    const { btnLoading } = this.state;
    let dvalue = 0;
    const cproducts = this.state.cartProduct;
    let selectedId = "";
    function fProducts(cproducts, id) {
      let selectedData = cproducts.filter((item) => item.name === id);
      var qty = 0;
      if (selectedData[0]) {
        qty = selectedData[0].qty;
      }

      return qty;
    }

    function fRupees(cproducts, id) {
      let selectedData = cproducts.filter((item) => item.name === id);
      var priceValues = 0;
      if (selectedData[0]) {
        const totalAmount = selectedData[0].qty * selectedData[0].unit_price;
        let show_value = 0;
        if(discount_type==='1')
        {
        show_value = (totalAmount * discountValue);
        priceValues = parseFloat(totalAmount) - parseFloat(show_value);
        }
        else
        {
            show_value = ((totalAmount) - (discountValue*selectedData[0].qty));
            priceValues =(show_value);
        }

      }

      return priceValues;
    }

    const productDetails = this.state.products.map((p, ind) => {
      return (
        <div className="row product_layout_n" key={ind}>
          <div className="col-sm-3 product_img_n">
            <img src={this.state.img_url + `/${p.image_name}`} />
          </div>
          <div className="col-sm-5 product_contents_n pl-0">
            <h4>
              {p.productName +
                "" +
                (p.UOMName ? " - (" + p.UOMName + ")" : "") +
                ""}
            </h4>
            <p>Discount: {localStorage.getItem("discount")} {sign}</p>
            <p>Unit price: {p.unit_price}</p>
          </div>
          <div className="col-sm-3 product_price_n p-0">
            <p>
              {this.state.prefix} {fRupees(cproducts, p.id)}
            </p>
            <div className="quantity_box">
              <input
                type="button"
                name={p.id}
                id={p.unit_price}
                title={p.productName}
                defaultValue="-"
                placeholder={p.UOMName}
                className="qty-minus minus_plus"
                onClick={this.decreaseQty.bind(this)}
              />
              <div className="value_div">{fProducts(cproducts, p.id)} </div>
              <input
                type="button"
                name={p.id}
                placeholder={p.UOMName}
                id={p.unit_price}
                title={p.productName}
                defaultValue="+"
                className="qty-plus minus_plus"
                onClick={this.increaseQty.bind(this)}
              />
            </div>
          </div>
        </div>
      );
    });

    if (this.state.redirectToReferrer) {
      return <Redirect to={`/`} />;
    }
    // if (localStorage.getItem('order_no')==null) {
    // return (<Redirect to={`/item-selection`}/>)
    // }

    if (this.state.redirect) {
      return <Redirect to={`/invoice-list`} />;
    }

    return (
      <div>
        <Header />
        <div className="main_body_n product_listing">
          <div className="logo_screen_n login_page ">
            <div className="row" style={{ textAlign: "center" }}>
              <div className="col-sm-6 search_porduct">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Here"
                  onChange={this.searchItem.bind(this)}
                />
              </div>
              <div className="cata-sub-slider">
                <div className="nav-prev- arrow" style={{ display: "none" }}>
                  <i className="fa fa-angle-left"></i>
                </div>
                <ul>
                  <li>
                    <a
                      name="0"
                      className="nav-item"
                      onClick={this.selectedCategory.bind(this)}
                    >
                      All
                    </a>
                  </li>
                  {this.state.categories != null ? (
                    this.state.categories.map((c, i) => (
                      <li key={i}>
                        <a
                          name={c.id}
                          className="nav-item"
                          onClick={this.selectedCategory.bind(this)}
                        >
                          {c.groupName}
                        </a>
                      </li>
                    ))
                  ) : (
                    <li>
                      <a
                        name="0"
                        className="nav-item"
                        onClick={this.selectedCategory.bind(this)}
                      >
                        All
                      </a>
                    </li>
                  )}
                </ul>
                <div className="nav-next- arrow">
                  <i className="fa fa-angle-right"></i>
                </div>
              </div>
            </div>

            <div className="user_login_n cart-page">
              <h3>Select Products</h3>

              {this.state.loading ? (
                <div><img src={loaderImage} alt='Loading' /></div>
              ) : this.state.products != null && this.state.products != "" ? (
                productDetails
              ) : (
                <div className="row product_layout_n">
                  <div className="col-sm-3 product_img_n"></div>
                  <div className="col-sm-5 product_contents_n pl-0">
                    No Result Found
                  </div>
                  <div className="col-sm-3 product_price_n p-0"></div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="total_btm-bar">
          <div className="total_amount_box">
            <b>Total:</b> <span>{parseFloat(this.state.total_amount).toFixed(this.state.decimalValue)}</span>
            <button
              className="button-loadings"
              type="button"
              onClick={this.saveProducts.bind(this)}
              disabled={btnLoading}
            >
              {btnLoading && (
                <i
                  className="fa fa-refresh fa-spin"
                  style={{ marginRight: "5px" }}
                />
              )}
              {btnLoading && <span></span>}
              {!btnLoading && (
                <span>
                  Next <i className="fa fa-long-arrow-right"></i>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default TimeSelection;
