import React, { Component } from "react";
import axios from "axios";
import Header from "./Header.js";
import Footer from "./Footer.js";
import {Redirect} from 'react-router-dom';
import {api_url} from '../Config.js';
import {NavLink} from 'react-router-dom';
import loading  from "../assets/images/loading.gif";
class ScrollComponent extends Component {
  constructor() {
    super();
    this.state = {
      photos: [],
      loading: false,
      page: 0,
      prevY: 0,
      decimalValue:0,
      redirectToReferrer: false,
      user_id:(JSON.parse(sessionStorage.getItem("userData")))?(JSON.parse(sessionStorage.getItem("userData"))):(JSON.parse(localStorage.getItem("userData"))),
      base_url:api_url.base_url,
      img_url:api_url.img_url,
      prefix:'',
      suffix:'',
      redirect:false,
    };
  }
   componentDidMount() {
    const decimalsUrl = this.state.base_url + "sale_api/getDecimals";
    fetch(decimalsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            decimalValue: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
    this.getPhotos(this.state.page);

    var options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      options
    );
    this.observer.observe(this.loadingRef);
  }
    getPhotos(page) {
    this.setState({ loading: true });
    axios
      .get(
        this.state.base_url+`sale_api/photos/${page}/7/${this.state.user_id}`
      )
      .then(res => {
        this.setState({ photos: [...this.state.photos, ...res.data] });
        this.setState({ loading: false });
        this.setState({page:this.state.page+parseFloat(7)})
      },(error) => {this.setState({loading:false})});
  }
handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y) {
      const lastPhoto = this.state.page;
      const curPage = lastPhoto ;
      this.getPhotos(curPage);
      this.setState({ page: curPage });
    }
    this.setState({ prevY: y });
  }

  componentWillMount() {
if(sessionStorage.getItem("userData")){
this.getUserData();
}else if(localStorage.getItem('userData')){
  this.getUserData();
}
else{
this.setState({redirectToReferrer: true});
}
}
getUserData() {
JSON.parse(sessionStorage.getItem("userData"));
JSON.parse(localStorage.getItem("userData"));

}
editData(id){
 if(id!==null)
 {
  localStorage.setItem('orderId',id)
  if(localStorage.getItem('orderId')!==null)
  {
    this.setState({redirect:true})
  }
 }
}

  render() {

const {photos} = this.state;
      // Additional css
    const loadingCSS = {
      height: "100px",
      margin: "0px 30px"
    };

    // To change the loading icon behavior
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };
 if (this.state.redirectToReferrer) {
return (<Redirect to={`/`}/>)
}
 if (this.state.redirect) {
return (<Redirect to={`/item-selection/${localStorage.getItem('orderId')}`}/>)
}
    return (
      <div>
      <Header />
      <div className="main_body">

          <div className=" order_lists" id="order_lists_load">
             <div className="user_login Select_language">
              <div className="form_box select_items_pdt">
                  <h3>Order List</h3>
                </div>
                {(photos!='')?

                  photos.map((item,key)=>(

                <div className="row product_layout" key={key}>
                  <div className="col-sm-2 product_img">
                    <img src={this.state.img_url+`${item.profile_img}`} />
                  </div>
                  <a onClick={()=>this.editData(item.saleOrderNo)} >

                  <div className="col-sm-8 product_contents">

                  <p>{item.created_on}</p>
                    <h4>{item.ledgerName }</h4>
                    <div className="quantity_box">
                    <b>{this.state.prefix } {(parseFloat(item.total)).toFixed(this.state.decimalValue)}{this.state.suffix}</b>
                  </div>
                  </div>
                  <div className="col-sm-2 product_price">
                  <br />
                  <p>Order#: {item.saleOrderNo}</p>
                   <p style={{marginTop:'9px'}}>{(item.saleOrderStatus==='0')? "Pending" : 'Approved' }</p>

                  </div>
                  </a>
                </div>
                  )):
                  <div className="row product_layout">
                  <div className="col-sm-8 product_contents">
                    <h4>No order yet</h4>
                    </div>
                </div>

                }


            </div>
          </div>
        </div>
         <div
          ref={loadingRef => (this.loadingRef = loadingRef)}
          style={loadingCSS}
        >
          <span style={loadingTextCSS} className="loader_on_listing"><img src={loading}  alt="Loading..."/></span>
        </div>
      </div>
    );
  }
}

export default ScrollComponent;
